<template>
  <v-container fluid>
    <div
      class="mr-4 mt-5 mb-5 bold"
    >
      <span class="font-weight-bold">{{$_strings.invoice.TITLE_CREATE}}</span>
    </div>
    <v-sheet>
      <v-row
        dense
        align="center"
        justify-md="start"
        class="pa-5"
      >
        <v-col
          cols="auto"
        >
          <v-menu
            ref="menuFromDate"
            v-model="menuFromDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                hide-details
                outlined
                class="caption"
                placeholder="From Date"
                :value="startDate ? dayjs(startDate).format('DD-MM-YYYY') : null"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              no-title
              :max="endDate"
              @change="menuFromDate = false"
              locale="id"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="auto">-</v-col>
        <v-col cols="auto">
          <v-menu
            ref="menuToDate"
            v-model="menuToDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                hide-details
                class="caption"
                outlined
                :value="endDate ? dayjs(endDate).format('DD-MM-YYYY') : null"
                placeholder="To Date"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :min="startDate"
              v-model="endDate"
              no-title
              @change="menuToDate = false"
              locale="id"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="auto"
          md="4"
          class="ml-md-auto"
          sm="12"
          xs="12"
        >
          <v-row align="center" justify-md="end">
            <v-col cols="auto">
              <span class="order-checked mr-2">Terpilih : {{checkedValue}} Pesanan</span>
              <v-btn
                :disabled="!checkedValue"
                color="primary"
                small
                @click="openDialog()"
              >{{$_strings.invoice.TITLE_CREATE}}</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :loading="isLoading"
            :headers="headers"
            calculate-widths
            :items="itemsInvoice"
            item-key="noInvoice"
            :server-items-length="invoiceListTotalEntry"
            :options.sync="pagination"
            :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
          >
            <template v-slot:[`header.checkbox`]="">
              <div class="d-flex">
                <span>
                  <v-checkbox
                    color="white"
                    class="d-inline"
                    v-model="checkAll"
                    :disabled="!itemsInvoice.length"
                    @change="handleCheckAll"
                    hide-details
                  />
                </span>
              </div>
            </template>
            <template v-slot:[`item.checkbox`]={item}>
              <v-checkbox v-model="item.checkbox"  class="itemCheck" ></v-checkbox>
            </template>
            <template v-slot:[`item.shipmentGroupTitle`]=items>
              <span>{{ items.item.shipmentGroupTitle }}</span>
              <br/>
              <span class="size-12">{{ dateFormat(items.item.shipmentDate) }}</span>
            </template>
            <template v-slot:[`item.shipperCompanyAbbreviation`]=items>
              <span v-if="items.item.shipmentCargoTitle">{{items.item.shipmentCargoTitle}} - {{ items.item.shipperCompanyAbbreviation }}</span>
              <span v-if="items.item.shipmentTitle">{{items.item.shipmentTitle}} - {{ items.item.shipperCompanyAbbreviation }}</span>
            </template>
            <template v-slot:[`item.transportTypeName`]=items>
              <span>{{ items.item.transportTypeName }}</span>
              <br/>
              <span class="size-12">{{ items.item.licensePlate }}</span>
            </template>
            <template v-slot:[`item.grandTotalAmount`]=items>
              <span>Rp. {{ formatAmount(items.item.grandTotalAmount) }}</span>
              <br/>
              <span class="size-12">{{ typePriceInvoice(items.item.typePrice) }}</span>
            </template>
            <template v-slot:[`item.status`]=items>
              <span>{{ items.item.status }}</span>
              <br/>
              <span class="size-12">{{ dateFormat(items.item.dateStatus) }}</span>
            </template>
            <template v-slot:[`item.createdBy`]=items>
              <span style="font-weight: bold">{{ items.item.createdBy }}</span>
              <br/>
              <span class="size-12">{{ items.item.createdDate }}</span>
            </template>
            <template v-slot:[`footer.page-text`]="props">
              <span>
                {{$_strings.invoice.ORDER_LIST}}
                <span v-if="itemsInvoice.length">
                  {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
                </span>
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-sheet>
    <v-row>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="750"
      >
        <v-card :loading="isLoadingCreateInvoice">
          <v-card-title>
            <div class="text-center">
              <h4>Masukkan Info Tambahan</h4>
            </div>
            <v-row>
              <v-col class="d-flex justify-end">
                <v-toolbar-items>
                  <v-btn
                    icon
                    color="black"
                    dark
                    @click="closeModal"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-col>
            </v-row>
          </v-card-title>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-subheader><span class="ml-1 text-bold">Rekening Bank</span></v-subheader>
              <v-row>
                <v-col cols="4">
                  <v-subheader><span style="color: red">*</span><span class="ml-1">Nama Bank</span></v-subheader>
                </v-col>
                <v-col>
                  <v-text-field
                    outlined
                    dense
                    v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
                    v-model="form.bankName"
                    :rules="rules.nameBank"
                    clearable
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-subheader><span style="color: red">*</span><span class="ml-1">A/C</span></v-subheader>
                </v-col>
                <v-col>
                  <v-text-field
                    outlined
                    dense
                    v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
                    v-model="form.bankAcc"
                    :rules="rules.aCLabel"
                    clearable
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-subheader><span style="color: red">*</span><span class="ml-1">A/N</span></v-subheader
                  >
                </v-col>
                <v-col>
                  <v-text-field
                    outlined
                    dense
                    v-model="form.bankAtt"
                    :rules="rules.aNLabel"
                    v-mask="{'alias': 'custom-integer',rightAlign: false}"
                    clearable
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-subheader><span style="color: red">*</span><span class="ml-1">Penanda Tangan 1</span></v-subheader
                  >
                </v-col>
                <v-col>
                  <v-text-field
                    outlined
                    dense
                    v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
                    v-model="form.signerLeftName"
                    :rules="rules.signerOneLabel"
                    clearable
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-subheader><span style="color: red">*</span><span class="ml-1">Jabatan Penanda Tangan 1</span></v-subheader
                  >
                </v-col>
                <v-col>
                  <v-text-field
                    outlined
                    dense
                    v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
                    v-model="form.signerLeftPosition"
                    :rules="rules.signatoryOneLabel"
                    clearable
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-subheader><span style="color: red">*</span><span class="ml-1">Penanda Tangan 2</span></v-subheader
                  >
                </v-col>
                <v-col>
                  <v-text-field
                    outlined
                    dense
                    v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
                    v-model="form.signerRightName"
                    :rules="rules.signerTwoLabel"
                    clearable
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-subheader><span style="color: red">*</span><span class="ml-1">Jabatan Penanda Tangan 2</span></v-subheader
                  >
                </v-col>
                <v-col>
                  <v-text-field
                    outlined
                    dense
                    v-mask="{'alias': 'alphabetic', allowMinus: false, rightAlign: false}"
                    v-model="form.signerRightPosition"
                    :rules="rules.signatoryTwoLabel"
                    clearable
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-btn
                  outlined
                  small
                  class="mr-2 ml-auto"
                  @click="closeModal"
                >
                  Batal
                </v-btn>
                <v-btn
                  depressed
                  small
                  color="primary"
                  class="white--text"
                  @click="createInvoice()"
                  :loading="isLoadingCreateInvoice"
                >
                  Simpan
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import { dateFormat, thousandSeparated, typePriceInvoice } from '../../helper/commonHelpers';

export default {
  name: 'InvoiceCreate',
  created() {
    this.invoiceCommonData();
  },
  data() {
    return {
      isLoadingCreateInvoice: false,
      isLoading: false,
      itemChecked: 0,
      itemsInvoice: [],
      valid: false,
      invoiceListSize: 10,
      invoiceListTotalEntry: 0,
      headers: [
        {
          text: this.$_strings.invoice.SELECT,
          value: 'checkbox',
          class: 'white--text primary',
          sortable: false,
        },
        {
          text: this.$_strings.invoice.ORDER_NO,
          value: 'shipmentGroupTitle',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.ORDER_NO_SHIPPER,
          value: 'shipperCompanyAbbreviation',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.ADD_TRANSPORT,
          value: 'transportTypeName',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.PRICE,
          value: 'grandTotalAmount',
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.invoice.INVOICE_DOCUMENT,
          value: 'status',
          class: 'white--text primary text-capitalize',
        },
      ],
      checkAll: false,
      pagination: {
        itemsPerPage: 10,
        page: 1,
        sortBy: [],
        sortDesc: [],
      },
      menuFromDate: false,
      startDate: dayjs().subtract('7', 'days').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
      menuToDate: false,
      dialog: false,
      form: {
        periodStart: '',
        periodEnd: '',
        transactionList: [
        ],
        bankAcc: '',
        bankAtt: '',
        bankName: '',
        signerLeftName: '',
        signerLeftPosition: '',
        signerRightName: '',
        signerRightPosition: '',
      },
      rules: {
        nameBank: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.invoiceData.nameBankLabel),
        ],
        aCLabel: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.invoiceData.aCLabel),
        ],
        aNLabel: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.invoiceData.aNLabel),
        ],
        signerOneLabel: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.invoiceData.signerOneLabel),
          (value) => {
            const pattern = /^[a-z ,.'-]+$/i;
            return pattern.test(value) || 'Tidak boleh menggunakan angka atau spesial character';
          },
        ],
        signatoryOneLabel: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.invoiceData.signatoryOneLabel),
          (value) => {
            const pattern = /^[a-z ,.'-]+$/i;
            return pattern.test(value) || 'Tidak boleh menggunakan angka atau spesial character';
          },
        ],
        signerTwoLabel: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.invoiceData.signerTwoLabel),
          (value) => {
            const pattern = /^[a-z ,.'-]+$/i;
            return pattern.test(value) || 'Tidak boleh menggunakan angka atau spesial character';
          },
        ],
        signatoryTwoLabel: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.invoiceData.signatoryTwoLabel),
          (value) => {
            const pattern = /^[a-z ,.'-]+$/i;
            return pattern.test(value) || 'Tidak boleh menggunakan angka atau spesial character';
          },
        ],
      },
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    myUserInfo() {
      return this.$store.state.user.myUserInfo;
    },
    checkedValue() {
      return this.itemsInvoice.filter((x) => x.checkbox).length;
    },
  },
  watch: {
    pagination: {
      handler() {
        if (!this.isLoading) this.fetchInvoice();
      },
      deep: true,
    },
    startDate() {
      this.setFilter();
    },
    endDate() {
      this.setFilter();
    },
    checkedValue() {
      this.checkAll = this.checkedValue === this.itemsInvoice.length;
    },
  },
  methods: {
    dayjs,
    dateFormat,
    handleCheckAll(value) {
      if (value) {
        this.itemsInvoice = this.itemsInvoice.map((data) => ({
          ...data,
          checkbox: true,
        }));
        return;
      }
      this.itemsInvoice = this.itemsInvoice.map((data) => ({
        ...data,
        checkbox: false,
      }));
    },
    setFilter() {
      if (this.pagination.page > 1) {
        this.pagination.page = 1;
        return;
      }
      this.fetchInvoice();
    },
    formatAmount(amount) {
      return thousandSeparated(amount);
    },
    typePriceInvoice(typePrice) {
      return typePriceInvoice(typePrice);
    },
    fetchInvoice() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        sort: '',
        dateFrom: this.startDate,
        dateTo: this.endDate,
      };
      if (filters.size < 0) filters.size = this.invoiceListSize;
      if (sortBy.length > 0) {
        const isDesc = sortDesc[0] ? 'DESC' : 'ASC';
        filters.sort = `${sortBy[0]},${isDesc}`;
      }
      this.isLoading = true;
      this.$_services.invoice.fetchInvoiceOrder(filters)
        .then((res) => {
          this.itemsInvoice = res.data.contents.map((data) => ({
            ...data,
            checkbox: false,
          }));
          this.invoiceListTotalEntry = res.data.totalData;
        }).finally(() => {
          this.isLoading = false;
        });
    },
    createShipmentList() {
      this.itemsInvoice.forEach((item) => {
        if (item.checkbox) {
          this.form.transactionList.push({
            id: item.id,
            shipmentCargoId: item.shipmentCargoId,
            shipmentCargoTitle: item.shipmentCargoTitle,
          });
        }
      });
    },
    createInvoice() {
      if (!this.$refs.form.validate()) {
        this.$nextTick(() => {
          const el = this.$el.querySelector('.v-messages.error--text:first-of-type');
          this.$vuetify.goTo(el, { offset: 50 });
        });
        return;
      }
      this.createShipmentList();
      this.isLoadingCreateInvoice = true;
      this.form.periodStart = this.startDate;
      this.form.periodEnd = this.endDate;
      this.$_services.invoice.createInvoice(this.form)
        .then(() => {
          this.$dialog.notify.success(this.$_strings.invoice.SUCCESS_INVOICE_MESSAGE_TEXT);
        }).finally(() => {
          this.isLoadingCreateInvoice = false;
          this.dialog = false;
          this.$router.push('/main/invoice-transporter/list');
        });
    },
    openDialog() {
      this.dialog = true;
    },
    closeModal() {
      this.dialog = false;
    },
    invoiceCommonData() {
      this.$root.$loading.show();
      this.$_services.invoiceData.invoiceCommonData()
        .then((res) => {
          this.form.bankAcc = res.data.bankAccountName;
          this.form.bankAtt = res.data.bankAccountNumber;
          this.form.bankName = res.data.bankName;
          this.form.signerLeftName = res.data.firstSignerName;
          this.form.signerLeftPosition = res.data.firstSignerPosition;
          this.form.signerRightName = res.data.secondSignerName;
          this.form.signerRightPosition = res.data.secondSignerPosition;
        }).finally((e) => {
          this.$root.$loading.hide();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-chip.v-size--default {
    font-size: 12px;
    margin-top: 3px;
}
.order-checked{
  font-size: 14px;
  color: #4d4f5c;
}
.size-12 {
  font-size: 12px;
}
</style>
